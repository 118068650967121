import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import ForgotPasswordPage from '../components/ForgotPasswordPage';
import { resetPassword } from '../actions/auth';

const selector = formValueSelector('forgotPassword');

function mapStateToProps(state) {
  return {
    currentEmail: selector(state, 'email')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onSubmit: resetPassword
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
