import moment from 'moment';

import axios from './axios';

export function next(count, reject) {
  return axios
    .get('/next', {
      params: {
        count,
        reject,
        mood: 0,
        now: moment().format('DD-MM-YYYY HH:mm')
      }
    })
    .then(({ data }) => data);
}

export function like(songId) {
  return axios.post('/like', {
    songId
  });
}

export function unlike(songId) {
  return axios.post('/unlike', {
    songId
  });
}

export function addToLastPlayed(songId) {
  return axios.post('/last_played', {
    songId
  });
}

export function postUserSettings(update) {
  return axios.post('/user/settings', update);
}
