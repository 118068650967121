import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect as RouterRedirect
} from 'react-router-dom';

import ConnectedLoginPage from './ConnectedLoginPage';
import ConnectedRegisterPage from './ConnectedRegisterPage';
import ConnectedForgotPasswordPage from './ConnectedForgotPasswordPage';
import ConnectedPasswordResetPage from './ConnectedPasswordResetPage';
import ConnectedSubscribeRedirect from './ConnectedSubscribeRedirect';
import LogoutPage from './LogoutPage';
import ConnectedPrivateRoute from './ConnectedPrivateRoute';

import VerifyRegistrationTokenPage from '../components/VerifyRegistrationTokenPage';

const download = () => {
  window.location.href = 'https://soundsuit.fm/download';
  return null;
};

const AppRouter = () => (
  <Router basename="/app">
    <Switch>
      <RouterRedirect from="/" exact to="/play" />
      <RouterRedirect from="/index.html" exact to="/" />
      <Route path="/login" component={ConnectedLoginPage} />
      <ConnectedPrivateRoute path="/subscribe" component={ConnectedSubscribeRedirect} />
      <Route path="/register" component={ConnectedRegisterPage} />
      <Route path="/forgot" exact component={ConnectedForgotPasswordPage} />
      <Route path="/forgot/:token" component={ConnectedPasswordResetPage} />
      <ConnectedPrivateRoute path="/logout" component={LogoutPage} />
      <Route path="/verify/:token" component={VerifyRegistrationTokenPage} />
      {/* <ConnectedPrivateRoute path="/play/:subpage?" component={ConnectedPlayerPage} /> */}
      <Route path="/play/:subpage?" component={download} />
      <Route path="/download" component={download} />
    </Switch>
  </Router>
);

export default AppRouter;
