import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import PasswordResetPage from '../components/PasswordResetPage';
import { setNewPassword, showResetPasswordSuccess } from '../actions/auth';

function mapStateToProps(state, ownProps) {
  const {
    match: {
      params: { token }
    }
  } = ownProps;
  return {
    token
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onSubmit: setNewPassword,
      onSubmitSuccess: showResetPasswordSuccess
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetPage);
