import glamorous from 'glamorous';
import { black, pinkPurple, pureWhite } from '../ui/colors';
import { gradientText } from '../ui/styles';

export const GreetingTitleContainer = glamorous.div({
  marginTop: '5vh'
});

export const DescriptionSection = glamorous.ul({
  listStyle: 'none',
  textAlign: 'center',
  fontSize: '20px',
  marginTop: '5vh',
  marginBottom: '5vh'
});

export const ButtonWrapper = glamorous.div({
  margin: 'auto',
  width: '80%',
  marginBottom: '4%'
});

export const DescriptionText = glamorous.li({
  marginTop: '12px',
  fontSize: '1rem',
  color: black,
  lineHeight: '4vh'
});

const greetingTitle = {
  fontSize: '5vh',
  textAlign: 'center'
};
export const GreetingWhiteTitle = glamorous.p({
  ...greetingTitle,
  color: pureWhite
});

export const GreetingBlackTitle = glamorous.p({
  ...greetingTitle,
  color: black
});

export const CheckIcon = glamorous.i({
  ...gradientText,
  fontSize: '60px',
  marginBottom: '5vh'
});
export const Email = glamorous.span({
  color: pinkPurple,
  fontWeight: 'bold'
});
