import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import Article, { ArticleContainer } from '../ui/shared/Article';
import { Container, Form, FormRow, styles } from './shared/Form';
import validate from './shared/validate';
import RegisterHeader from './items/RegisterHeader';
import LanguagePicker from './items/LanguagePicker';
import RegisterMobileStepper from './items/RegisterMobileStepper';
import component from './util/renderComponents';

const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const COMPANY_NAME = 'companyName';

const FIRST_NAME_SNAKE = _.snakeCase(FIRST_NAME);
const LAST_NAME_SNAKE = _.snakeCase(LAST_NAME);
const COMPANY_NAME_SNAKE = _.snakeCase(COMPANY_NAME);

const RegisterInfoScreen = ({
  t,
  submitting,
  invalid,
  goToGreetingScreen,
  goToCredentialsScreen,
  changeLanguage,
  classes
}) => (
  <Container isTransparent>
    <ArticleContainer>
      <Article>
        <RegisterHeader goToScreen={goToGreetingScreen} />
        <Form onSubmit={goToCredentialsScreen}>
          <FormRow>
            <Field
              required
              name={FIRST_NAME_SNAKE}
              type="text"
              component={component.renderField}
              label={t(`register.${FIRST_NAME}`)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Icon className={classNames(classes.icon, 'fas fa-user')} />
                  </InputAdornment>
                )
              }}
            />
          </FormRow>
          <FormRow>
            <Field
              required
              name={LAST_NAME_SNAKE}
              type="text"
              component={component.renderField}
              label={t(`register.${LAST_NAME}`)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Icon className={classNames(classes.icon, 'fas fa-user')} />
                  </InputAdornment>
                )
              }}
            />
          </FormRow>
          <FormRow>
            <Field
              required
              name={COMPANY_NAME_SNAKE}
              type="text"
              component={component.renderField}
              label={t(`register.${COMPANY_NAME}`)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Icon className={classNames(classes.icon, 'fas fa-user')} />
                  </InputAdornment>
                )
              }}
            />
          </FormRow>
          <Field
            name="language"
            component={LanguagePicker}
            onChange={(event) => changeLanguage(event.target.value)}
          />
          <RegisterMobileStepper
            submitting={submitting}
            invalid={invalid}
            activeStep={0}
            text="next"
          />
        </Form>
      </Article>
    </ArticleContainer>
  </Container>
);

RegisterInfoScreen.propTypes = {
  t: PropTypes.func.isRequired,
  goToGreetingScreen: PropTypes.func.isRequired,
  goToCredentialsScreen: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired
  }).isRequired
};

export default connect(({ settings: { language } }) => ({
  initialValues: {
    language
  }
}))(
  reduxForm({
    form: 'registerPage',
    destroyOnUnmount: false,
    validate
  })(withTranslation()(withStyles(styles)(RegisterInfoScreen)))
);
