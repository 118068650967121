import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hasSubmitSucceeded, reset, formValueSelector } from 'redux-form';
import RegisterScreens from '../components/registerPage/RegisterScreens';
import { register } from '../actions/auth';
import { changeLanguageWithoutRequest } from '../actions/settings';

const selector = formValueSelector('registerPage');

function mapStateToProps(state) {
  return {
    email: selector(state, 'email'),
    submitSucceeded: hasSubmitSucceeded('registerPage')(state),
    language: selector(state, 'language')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onSubmit: register,
      reset: () => reset('registerPage'),
      changeLanguage: changeLanguageWithoutRequest
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterScreens);
