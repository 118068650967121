import React from 'react';
import ReactDOM from 'react-dom';

import Root from './containers/Root';
import { unregister } from './registerServiceWorker';

import store from './store';
import { init } from './actions/app';

store.dispatch(init());

ReactDOM.render(<Root />, document.getElementById('root'));

unregister();
