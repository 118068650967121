import glamorous from 'glamorous';

const ContainerIconButton = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '5vh',
  marginTop: '5vh'
});

export default ContainerIconButton;
