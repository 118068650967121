import { combineReducers } from 'redux';

import {
  ENABLE_SCHEDULE,
  CLEANUP_SCHEDULE,
  TICK_SCHEDULE_START,
  TICK_SCHEDULE_STOP
} from '../actions/schedule';

function enabled(state = false, action) {
  switch (action.type) {
    case ENABLE_SCHEDULE:
      return true;
    default:
      return state;
  }
}

function startInterval(state = null, action) {
  switch (action.type) {
    case CLEANUP_SCHEDULE:
      return null;
    case ENABLE_SCHEDULE:
      return action.payload.startInterval;
    default:
      return state;
  }
}

function lastTickStart(state = null, action) {
  switch (action.type) {
    case TICK_SCHEDULE_START:
      return Date.now();
    default:
      return state;
  }
}

function lastTickStop(state = null, action) {
  switch (action.type) {
    case TICK_SCHEDULE_STOP:
      return Date.now();
    default:
      return state;
  }
}

function stopInterval(state = null, action) {
  switch (action.type) {
    case CLEANUP_SCHEDULE:
      return null;
    case ENABLE_SCHEDULE:
      return action.payload.stopInterval;
    default:
      return state;
  }
}

export default combineReducers({
  enabled,
  startInterval,
  stopInterval,
  lastTickStart,
  lastTickStop
});
