import React from 'react';
import { Field } from 'redux-form';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withTranslation } from 'react-i18next';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';
import { FormRow } from '../shared/Form';
import IconButton from '../../ui/IconButton';
import components from '../util/renderComponents';

class Password extends React.Component {
  state = {
    passwordVisibility: false
  };

  changeVisibility = () => {
    this.setState((previosState) =>
      this.setState({ passwordVisibility: !previosState.passwordVisibility })
    );
  };

  render() {
    const { t } = this.props;
    return (
      <FormRow>
        <Field
          required
          autoComplete="current-password"
          name="password"
          type={this.state.passwordVisibility ? 'text' : 'password'}
          component={components.renderField}
          label={t('register.password.label')}
          helperText={t('register.passwordHelper')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="Toggle password visibility" onClick={this.changeVisibility}>
                  {this.state.passwordVisibility ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </FormRow>
    );
  }
}

Password.propTypes = {
  t: PropTypes.func.isRequired
};
export default withTranslation()(Password);
