import React from 'react';
import { withTranslation } from 'react-i18next/src/index';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import Article, { ArticleContainer } from '../ui/shared/Article';
import ContainerIconButton from '../ui/ContainerIconButton';
import { Container, Header } from './shared/Form';
import { ButtonWrapper, DescriptionSection, GreetingTitleContainer } from './RegisterTemplateItems';

const RegisterTemplateNoForm = ({
  iconButton,
  isTransparent,
  hasBottomNeighbour,
  greetingTitle,
  description,
  button,
  children,
  target
}) => (
  <Container>
    <ArticleContainer>
      <Article isTransparent={isTransparent} hasBottomNeighbour={hasBottomNeighbour}>
        <Header textCenter={false}>
          <ContainerIconButton>{iconButton}</ContainerIconButton>
          <GreetingTitleContainer>{greetingTitle}</GreetingTitleContainer>
        </Header>
        <DescriptionSection>{description}</DescriptionSection>
        <Link to={target}>
          <ButtonWrapper>{button}</ButtonWrapper>
        </Link>
      </Article>
      {children}
    </ArticleContainer>
  </Container>
);

RegisterTemplateNoForm.propTypes = {
  iconButton: PropTypes.element,
  greetingTitle: PropTypes.element,
  description: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  button: PropTypes.element,
  isTransparent: PropTypes.bool,
  hasBottomNeighbour: PropTypes.bool,
  target: PropTypes.string.isRequired,
  children: PropTypes.node
};

RegisterTemplateNoForm.defaultProps = {
  iconButton: null,
  greetingTitle: null,
  description: null,
  button: null,
  isTransparent: false,
  hasBottomNeighbour: false,
  children: null
};

export default withTranslation()(RegisterTemplateNoForm);
