import { pinkPurple, tangerine } from './colors';

let supportsTextGradient = false;

try {
  supportsTextGradient = CSS.supports(
    '(-webkit-text-fill-color: #fff) and (-webkit-background-clip: text)'
  );
} catch (e) {
  /* eslint:disable no-empty */
}

export const gradientText = supportsTextGradient
  ? {
      background: `linear-gradient(to right, ${pinkPurple}, ${tangerine})`,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent'
    }
  : {
      color: pinkPurple
    };

export const btnReset = {
  fontFamily: 'inherit',
  background: 'none',
  border: 'none',
  ':focus': {
    outline: 'none'
  }
};

export const helvetica = {
  fontFamily: 'Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif'
};

export const gradientBg = {
  background: `linear-gradient(to bottom, ${tangerine}, ${pinkPurple})`
};

export const gradientBg225 = {
  background: `linear-gradient(225deg, ${tangerine}, ${pinkPurple})`
};

export const container = {
  margin: '0 auto',
  maxWidth: '1180px',
  width: '100%'
};
