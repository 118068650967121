import store from '../store';
import { trialExpiredApiError } from '../actions/auth';

export default [
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 498) {
      store.dispatch(trialExpiredApiError());
    }
    return Promise.reject(error);
  }
];
