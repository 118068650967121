import { changeVoiceLanguage, restoreVoiceLanguage } from './settingsSporty';

export const ENABLE_SWITCHING_VOICE = 'ENABLE_SWITCHING_VOICE';
export const DISABLE_SWITCHING_VOICE = 'DISABLE_SWITCHING_VOICE';
export const TICK_SWITCHING_VOICE = 'TICK_SWITCHING_VOICE';
export const SET_SWITCHING_VOICE_INTERVAL = 'SET_SWITCHING_VOICE_INTERVAL';
export const REMOVE_SWITCHING_VOICE_INTERVAL = 'REMOVE_SWITCHING_VOICE_INTERVAL';
export const SWITCHING_VOICE_ENDED = 'SWITCHING_VOICE_ENDED';
export const SWITCHING_VOICE_VOLUME_CHANGE = 'SWITCHING_VOICE_VOLUME_CHANGE';

const tickSwitchingVoice = () => ({
  type: TICK_SWITCHING_VOICE
});

const enableSwitchingVoice = (interval) => (dispatch, getState) => {
  dispatch({
    type: ENABLE_SWITCHING_VOICE
  });

  const { switchingVoice } = getState();
  if (!switchingVoice.interval) {
    dispatch({
      type: SET_SWITCHING_VOICE_INTERVAL,
      payload: {
        interval: setInterval(() => dispatch(tickSwitchingVoice()), interval)
      }
    });
  }
};

export const disableSwitchingVoice = () => (dispatch, getState) => {
  dispatch({
    type: DISABLE_SWITCHING_VOICE
  });

  const { switchingVoice } = getState();

  clearInterval(switchingVoice.interval);

  dispatch({
    type: REMOVE_SWITCHING_VOICE_INTERVAL
  });
};

export const enableSwitchingVoiceIfNeeded = () => (dispatch, getState) => {
  const { currentUser } = getState();

  if (currentUser && currentUser.changing_voice) {
    dispatch(enableSwitchingVoice(currentUser.changing_voice_interval * 1000));
  }
};

export const toggleSwitchingVoice = (value) => (dispatch, getState) => {
  const { switchingVoice } = getState();

  if (switchingVoice.enabled && !value) {
    dispatch(disableSwitchingVoice());
    return;
  }

  if (!switchingVoice.enabled && value) {
    dispatch(enableSwitchingVoiceIfNeeded());
  }
};

export const onSwitchingVoiceEnd = () => ({
  type: SWITCHING_VOICE_ENDED
});

export const onSwitchingVoiceVolumeChange = (volume) => (dispatch, getState) => {
  const {
    switchingVoice: { volume: currentVolume },
    currentUser: { changing_voice: changingVoiceLanguage }
  } = getState();

  if (volume === 0 && currentVolume !== 0 && changingVoiceLanguage) {
    dispatch(changeVoiceLanguage(null));
  }

  if (volume !== 0 && currentVolume === 0 && !changingVoiceLanguage) {
    dispatch(restoreVoiceLanguage());
  }

  dispatch({
    type: SWITCHING_VOICE_VOLUME_CHANGE,
    payload: {
      volume
    }
  });
};
