import { LOAD_PROFILE_SUCCESS, LOGOUT_SUCCESS } from '../actions/auth';
import {
  CHANGE_MUSIC_STYLE,
  CHANGE_CUSTOMER_AGE,
  CHANGE_BUSINESS_TYPE,
  CHANGE_MUSIC_FLAVOR
} from '../actions/settings';
import { UPDATE_PROFILE } from '../actions/profile';
import {
  CHANGE_WORKOUT_TYPE,
  CHANGE_VOICE_LANGUAGE,
  CHANGE_SCHEDULE
} from '../actions/settingsSporty';

function toggleMusicFlavor(state, action) {
  const flavor = action.payload.value;
  const index = state.music_flavors.indexOf(flavor);

  return {
    ...state,
    music_flavors:
      index === -1
        ? [...state.music_flavors, flavor]
        : [...state.music_flavors.slice(0, index), ...state.music_flavors.slice(index + 1)]
  };
}

function setCurrentUser(state, action) {
  return {
    ...action.payload.user,
    session_end: action.payload.user.session_end ? new Date(action.payload.user.session_end) : null,
    trial_end: action.payload.user.trial_end ? new Date(action.payload.user.trial_end) : null
  };
}

export default function currentUser(state = null, action) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return null;
    case LOAD_PROFILE_SUCCESS:
      return setCurrentUser(state, action);
    case CHANGE_MUSIC_STYLE:
      return {
        ...state,
        music_style: action.payload.value
      };
    case CHANGE_CUSTOMER_AGE:
      return {
        ...state,
        customer_age: action.payload.value
      };
    case CHANGE_BUSINESS_TYPE:
      return {
        ...state,
        business_type: action.payload.value
      };
    case CHANGE_MUSIC_FLAVOR:
      return toggleMusicFlavor(state, action);

    case CHANGE_WORKOUT_TYPE:
      return {
        ...state,
        workout_type: action.payload.value
      };

    case CHANGE_VOICE_LANGUAGE:
      return {
        ...state,
        changing_voice: action.payload.value,
        previous_changing_voice: state.changing_voice
      };
    case CHANGE_SCHEDULE:
      return {
        ...state,
        ...action.payload.value
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        ...action.payload.value,
        company_address: {
          ...(state.company_address || {}),
          ...(action.payload.value && action.payload.value.company_address)
        }
      };
    default:
      return state;
  }
}
