import React from 'react';
import { Button, I, Span } from 'glamorous';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ContainerIconButton from '../../ui/ContainerIconButton';
import IconButton from '../../ui/IconButton';
import { Header } from '../shared/Form';

const RegisterHeader = ({ t, goToScreen }) => (
  <Header>
    <ContainerIconButton>
      <IconButton active>
        <I className="icon icon-soundsuit" fontSize="48px" />
      </IconButton>
    </ContainerIconButton>
    <Span marginBottom="24px" marginRight="24px">
      <Button type="button" className="btn-reset" onClick={goToScreen}>
        <I className="fa fa-chevron-left" fontSize="24px" />
      </Button>
    </Span>
    {t('register.title')}
  </Header>
);

RegisterHeader.propTypes = {
  t: PropTypes.func.isRequired,
  goToScreen: PropTypes.func.isRequired
};

export default withTranslation()(RegisterHeader);
