import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import glamorous from 'glamorous';
import { withTranslation } from 'react-i18next';

import LoadingScreen from './LoadingScreen';
import { postVerifyToken } from '../services/auth';

import { container } from './ui/styles';
import { orangeRed } from './ui/colors';

const LoginError = glamorous.p({
  color: 'white',
  backgroundColor: orangeRed,
  borderRadius: '12px',
  margin: '20px auto',
  padding: '5px 30px',
  fontWeight: 600,
  textAlign: 'center',
  fontSize: '14px'
});

const Container = glamorous.article({
  ...container
});

class VerifyRegistrationTokenPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        token: PropTypes.string
      })
    }).isRequired,
    t: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {};
  }

  async UNSAFE_componentWillMount() {
    const {
      match: {
        params: { token }
      }
    } = this.props;
    try {
      await postVerifyToken(token);
    } catch (err) {
      this.setState({
        error: true,
        errorCode: err.response && err.response.data.code ? err.response.data.code : null
      });
    }

    this.setState({ loaded: true });
  }

  render() {
    const { loaded, error, errorCode } = this.state;

    const { t } = this.props;
    if (!loaded) return <LoadingScreen />;

    if (error) {
      return (
        <Container>
          <LoginError>
            {errorCode ? t(`register.verify.errors.${errorCode}`) : t('unknownError')}
          </LoginError>
        </Container>
      );
    }

    window.location.href = t('downloadUrl');
    return;
  }
}

export default withTranslation()(VerifyRegistrationTokenPage);
