import React from 'react';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import validate from './shared/validate';
import { Container, Form, LoginError, styles } from './shared/Form';
import Article, { ArticleContainer } from '../ui/shared/Article';
import Email from './items/Email';
import Password from './items/Password';
import AgreeToReceiveProductUpdates from './items/AgreeToReceiveProductUpdates';
import AgreeTermsAndConditions from './items/AgreeTermsAndConditions';
import RegisterMobileStepper from './items/RegisterMobileStepper';
import RegisterHeader from './items/RegisterHeader';

const RegisterCredentialsScreen = ({
  error,
  t,
  classes,
  submitting,
  invalid,
  goToRegisterInfoScreen,
  handleSubmit,
  language
}) => (
  <Container isTransparent={false}>
    <ArticleContainer>
      <Article>
        <RegisterHeader goToScreen={goToRegisterInfoScreen} />
        <Form id="registerPage" onSubmit={handleSubmit}>
          <Email classes={classes} />
          <Password />
          <AgreeToReceiveProductUpdates classes={classes} />
          <AgreeTermsAndConditions classes={classes} language={language} />
          <RegisterMobileStepper
            classes={classes}
            submitting={submitting}
            invalid={invalid}
            activeStep={1}
            text="trialButton"
          >
            {error && (
              <LoginError>
                {error.code
                  ? t(`register.errors.${error.code}`) || t(`register.errors.${error.code}`)
                  : t('unknownError')}
              </LoginError>
            )}
          </RegisterMobileStepper>
        </Form>
      </Article>
    </ArticleContainer>
  </Container>
);

RegisterCredentialsScreen.propTypes = {
  t: PropTypes.func.isRequired,
  goToRegisterInfoScreen: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired
  }).isRequired,
  error: PropTypes.shape(),
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired
};

RegisterCredentialsScreen.defaultProps = {
  error: null
};

export default reduxForm({
  form: 'registerPage',
  destroyOnUnmount: false,
  validate
})(withTranslation()(withStyles(styles)(RegisterCredentialsScreen)));
