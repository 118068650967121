import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class BackButton extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  static contextTypes = {
    router: () => null
  };

  render() {
    return (
      <button type="button" className="btn-reset" onClick={this.context.router.history.goBack}>
        {this.props.children}
      </button>
    );
  }
}
