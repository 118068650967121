import { combineReducers } from 'redux';
import {
  ENABLE_SWITCHING_VOICE,
  DISABLE_SWITCHING_VOICE,
  SET_SWITCHING_VOICE_INTERVAL,
  REMOVE_SWITCHING_VOICE_INTERVAL,
  TICK_SWITCHING_VOICE,
  SWITCHING_VOICE_ENDED,
  SWITCHING_VOICE_VOLUME_CHANGE
} from '../actions/switchingVoice';
import { DEFAULT_SWITCHING_VOICE_VOLUME } from '../config';

function enabled(state = false, action) {
  switch (action.type) {
    case ENABLE_SWITCHING_VOICE:
      return true;
    case DISABLE_SWITCHING_VOICE:
      return false;
    default:
      return state;
  }
}

function playing(state = false, action) {
  switch (action.type) {
    case TICK_SWITCHING_VOICE:
      return true;
    case SWITCHING_VOICE_ENDED:
      return false;
    case DISABLE_SWITCHING_VOICE:
      return false;
    default:
      return state;
  }
}

function interval(state = null, action) {
  switch (action.type) {
    case SET_SWITCHING_VOICE_INTERVAL:
      return action.payload.interval;
    case REMOVE_SWITCHING_VOICE_INTERVAL:
      return null;
    default:
      return state;
  }
}

function lastTickedAt(state = null, action) {
  switch (action.type) {
    case TICK_SWITCHING_VOICE:
      return Date.now();
    case DISABLE_SWITCHING_VOICE:
      return null;
    default:
      return state;
  }
}

function volume(state = DEFAULT_SWITCHING_VOICE_VOLUME, action) {
  switch (action.type) {
    case SWITCHING_VOICE_VOLUME_CHANGE:
      return action.payload.volume;
    default:
      return state;
  }
}

export default combineReducers({
  enabled,
  playing,
  interval,
  lastTickedAt,
  volume
});
