import React from 'react';
import glamorous, { I, Div, Span } from 'glamorous';
import { withTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { Redirect, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import { gradientBg225 } from './ui/styles';
import { orangeRed, black } from './ui/colors';

import BackButton from './ui/BackButton';
import GradientBorderButton from './ui/GradientBorderButton';
import Article from './ui/shared/Article';
import ContainerIconButton from './ui/ContainerIconButton';
import IconButton from './ui/IconButton';
import { MIN_PASSWORD_LENGTH } from '../config';
import { Form, FormRow, Header, styles } from './registerPage/shared/Form';
import Email from './registerPage/items/Email';
import Password from './registerPage/items/Password';
import withMeta from './withMeta';

const Container = glamorous.main({
  ...gradientBg225,
  minHeight: '100%',
  minWidth: '100%',
  height: '90%',
  display: 'flex',
  maxHeight: '625px'
});

const LoginError = glamorous.p({
  color: 'white',
  backgroundColor: orangeRed,
  borderRadius: '12px',
  margin: '20px auto',
  padding: '5px 30px',
  fontWeight: 600,
  textAlign: 'center',
  fontSize: '14px'
});

const LinkHolder = glamorous.div({
  textAlign: 'center',
  color: black,
  fontSize: '14px',
  marginTop: '15px',
  marginBottom: '15px'
});

const validate = ({ email, password }) => {
  const errors = {};

  if (!email) {
    errors.email = 'required';
  }

  if (!password || password.length < MIN_PASSWORD_LENGTH) {
    errors.password = 'required';
  }

  return errors;
};

const LoginPage = ({ error, invalid, submitSucceeded, t, classes, handleSubmit }) => (
  <Container isTransparent>
    <Article>
      <Header>
        <ContainerIconButton>
          <IconButton active>
            <I className="icon icon-soundsuit" fontSize="48px" />
          </IconButton>
        </ContainerIconButton>
        <Span marginBottom="24px" marginRight="24px">
          <BackButton>
            <I className="fa fa-chevron-left" fontSize="24px" />
          </BackButton>
        </Span>

        {t('login.title')}
      </Header>
      <Form id="registerPage" onSubmit={handleSubmit}>
        <Email classes={classes} />
        <Password />
        <Div marginTop="auto">
          <GradientBorderButton disabled={invalid} fontSize="1rem" lineHeight="1.4">
            {t('login.button')}
          </GradientBorderButton>
          <FormRow>
            <LinkHolder>
              <NavLink to="/register">{t('login.createAccount')}</NavLink>
            </LinkHolder>
          </FormRow>
          <FormRow>
            <LinkHolder>
              <NavLink to="/forgot">{t('login.forgotPassword')}</NavLink>
            </LinkHolder>
          </FormRow>
          {error && (
            <LoginError>
              {error.code ? t(`login.errors.${error.code}`) : t('login.error')}
            </LoginError>
          )}
        </Div>
      </Form>

      {submitSucceeded && <Redirect to="/play" />}
    </Article>
  </Container>
);

LoginPage.defaultProps = {
  error: ''
};

LoginPage.propTypes = {
  error: PropTypes.string,
  invalid: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  validate,
  form: 'login'
})(withTranslation()(withStyles(styles)(withMeta(LoginPage, { title: 'login.meta.title' }))));
