import React from 'react';
import { Field } from 'redux-form';
import { Trans, withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ConditionsLabelWrapper, FormRow } from '../shared/Form';
import components from '../util/renderComponents';
import { ENGLISH, PRIVACY_AND_POLICY_URL, TERMS_AND_CONDITIONS_URL } from '../../../config';

const AgreeTermsAndConditions = ({ classes, language }) => (
  <FormRow smaller>
    <Field
      name="accept_tos"
      component={components.renderCheckbox}
      type="checkbox"
      label={
        <ConditionsLabelWrapper>
          <Trans i18nKey="register.toc_agreement">
            I agree to
            <a
              href={TERMS_AND_CONDITIONS_URL[language] || TERMS_AND_CONDITIONS_URL[ENGLISH]}
              target="_blank"
              rel="noreferrer noopener"
            >
              toc
            </a>
            <a
              href={PRIVACY_AND_POLICY_URL[language] || PRIVACY_AND_POLICY_URL[ENGLISH]}
              target="_blank"
              rel="noreferrer noopener"
            >
              pp
            </a>
          </Trans>
        </ConditionsLabelWrapper>
      }
      classes={{
        switchBase: classes.colorSwitchBase,
        checked: classes.colorChecked,
        bar: classes.colorBar
      }}
    />
  </FormRow>
);

AgreeTermsAndConditions.propTypes = {
  classes: PropTypes.shape().isRequired,
  language: PropTypes.string.isRequired
};
export default withTranslation()(AgreeTermsAndConditions);
