import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import LoadingScreen from '../components/LoadingScreen';
import { logout } from '../actions/auth';

export class Logout extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = { complete: false };
  }

  async componentDidMount() {
    await this.props.logout();
    this.setState({ complete: true });
  }

  render() {
    if (!this.state.complete) {
      return <LoadingScreen />;
    }

    return <Redirect to="/login" />;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Logout);
