import glamorous from 'glamorous';
import { gradientText, btnReset } from './styles';
import { greyIcon, pureWhite } from './colors';

const IconButton = glamorous.button(
  {
    ...btnReset,
    color: greyIcon
  },
  ({ active, white }) => ({
    color: white ? pureWhite : greyIcon,
    ...(active ? gradientText : null)
  })
);

export default IconButton;
