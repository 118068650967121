import { INITIALIZE, INITIALIZE_COMPLETE } from '../actions/app';
import { AnyAction } from 'redux';

export default function app(
  state = {
    initComplete: false
  },
  action: AnyAction
) {
  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        initComplete: false
      };
    case INITIALIZE_COMPLETE:
      return {
        ...state,
        initComplete: true
      };
    default:
      return state;
  }
}
