import React from 'react';
import glamorous from 'glamorous';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { darkGrey } from '../ui/colors';
import {
  CONTACT_URL,
  ENGLISH,
  IMPRINT_URL,
  PRIVACY_AND_POLICY_URL,
  TERMS_AND_CONDITIONS_URL
} from '../../config';

const LegalArticle = glamorous.article({
  height: '30px',
  width: '100%',
  margin: 'auto',
  marginTop: '5px',
  maxWidth: '700px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex'
});

const LegalLink = glamorous.div({
  marginRight: 'auto',
  marginLeft: 'auto',
  wordBreak: 'break-all',
  padding: '7px'
});

const LegalLinks = ({ t, language }) => (
  <LegalArticle>
    <LegalLink>
      <a
        href={PRIVACY_AND_POLICY_URL[language] || PRIVACY_AND_POLICY_URL[ENGLISH]}
        rel="noreferrer noopener"
        style={{ textDecoration: 'none', color: darkGrey }}
        target="_blank"
      >
        {t('legalLinks.privacy')}
      </a>
    </LegalLink>
    <LegalLink>
      <a
        href={TERMS_AND_CONDITIONS_URL[language] || TERMS_AND_CONDITIONS_URL[ENGLISH]}
        rel="noreferrer noopener"
        style={{ textDecoration: 'none', color: darkGrey }}
        target="_blank"
      >
        {t('legalLinks.terms')}
      </a>
    </LegalLink>
    <LegalLink>
      <a
        href={IMPRINT_URL[language] || IMPRINT_URL[ENGLISH]}
        rel="noreferrer noopener"
        style={{ textDecoration: 'none', color: darkGrey }}
        target="_blank"
      >
        {t('legalLinks.imprint')}
      </a>
    </LegalLink>
    <LegalLink>
      <a
        href={CONTACT_URL[language] || CONTACT_URL[ENGLISH]}
        rel="noreferrer noopener"
        style={{ textDecoration: 'none', color: darkGrey }}
        target="_blank"
      >
        {t('legalLinks.contact')}
      </a>
    </LegalLink>
  </LegalArticle>
);

LegalLinks.propTypes = {
  t: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired
};

export default withTranslation()(LegalLinks);
