import NativeSelect from '@material-ui/core/NativeSelect';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { LANGUAGES } from '../../config';

function LanguageSelect({
  t,
  value,
  onChange
}: React.PropsWithChildren<
  WithTranslation & {
    value: LANGUAGES;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  }
>) {
  return (
    <NativeSelect value={value} onChange={onChange}>
      {Object.values(LANGUAGES).map((lang) => (
        <option value={lang} key={lang}>
          {t(`language.${lang}`)}
        </option>
      ))}
    </NativeSelect>
  );
}

export default withTranslation()(LanguageSelect);
