import React from 'react';
import { Field } from 'redux-form';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FormRow } from '../shared/Form';
import components from '../util/renderComponents';

const Email = ({ t, classes }) => (
  <FormRow>
    <Field
      autoComplete="email"
      required
      name="email"
      type="email"
      component={components.renderField}
      label={t('register.email.label')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <Icon className={classNames(classes.icon, 'fas fa-user')} />
          </InputAdornment>
        )
      }}
    />
  </FormRow>
);

Email.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired
  }).isRequired
};
export default withTranslation()(Email);
