import { SubmissionError } from 'redux-form';
import { success } from 'react-notification-system-redux';

import {
  loadUserProfile,
  postLogout,
  postLogin,
  postRegister,
  postResetPassword,
  postNewPassword
} from '../services/auth';

import i18n from '../i18n';

import { setLanguage } from './settings';

export const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const TRIAL_EXPIRED_API_ERROR = 'TRIAL_EXPIRED_API_ERROR';
export const CLEANUP_AUTH_REDIRECT = 'CLEANUP_AUTH_REDIRECT';

const maybeSetGaUserId = (user) => {
  if (typeof window.ga === 'function') {
    window.ga('set', 'userId', user.email);
  }
};

export const loadProfile = () => async (dispatch, getState) => {
  const user = await loadUserProfile();

  dispatch({
    type: LOAD_PROFILE_SUCCESS,
    payload: {
      user
    }
  });

  if (user.language && !getState().settings.languageOverride) {
    dispatch(setLanguage(user.language));
  }
  maybeSetGaUserId(user);
};

export const trialExpiredApiError = () => ({
  type: TRIAL_EXPIRED_API_ERROR
});

export const cleanupRedirect = () => ({
  type: CLEANUP_AUTH_REDIRECT
});

export const logout = () => async (dispatch) => {
  await postLogout();

  dispatch({
    type: LOGOUT_SUCCESS
  });
};

export const login = (data) => async (dispatch, getState) => {
  try {
    const user = await postLogin(data);
    dispatch({
      type: LOAD_PROFILE_SUCCESS,
      payload: {
        user
      }
    });
    if (user.language && !getState().settings.languageOverride) {
      dispatch(setLanguage(user.language));
    }
    maybeSetGaUserId(user);
  } catch (err) {
    if (err.response && err.response.data) {
      throw new SubmissionError({
        _error: err.response.data
      });
    }

    throw err;
  }
};

export const register = (data) => async () => {
  try {
    await postRegister({
      ...data,
      trackingId: (window.$FPROM && window.$FPROM.data && window.$FPROM.data.tid) || null
    });
    if (window.$FPROM) {
      window.$FPROM.trackSignup(
        {
          email: data.email
        },
        () => {
          //
        }
      );
    }
  } catch (err) {
    if (err.response && err.response.data) {
      throw new SubmissionError({
        _error: err.response.data
      });
    }
    throw err;
  }
};

export const resetPassword = (data) => async () => {
  try {
    await postResetPassword(data);
  } catch (err) {
    if (err.response && err.response.data) {
      throw new SubmissionError({
        _error: err.response.data
      });
    }

    throw err;
  }
};

export const setNewPassword = (data, dispatch, { token }) => async () => {
  try {
    await postNewPassword({
      ...data,
      token
    });
  } catch (err) {
    if (err.response && err.response.data) {
      throw new SubmissionError({
        _error: err.response.data
      });
    }

    throw err;
  }
};

export const showResetPasswordSuccess = () => (dispatch) => {
  dispatch(
    success({
      title: i18n.t('resetPassword.successNotification.title'),
      message: i18n.t('resetPassword.successNotification.message')
    })
  );
};
