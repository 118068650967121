import { postUserSettings } from '../services/api';
import { reload as resetQueue } from './queue';
import { enableSwitchingVoiceIfNeeded, disableSwitchingVoice } from './switchingVoice';
import { cleanupSchedule, possiblyEnableSchedule } from './schedule';
import { timeToCron } from '../lib/time';

export const CHANGE_WORKOUT_TYPE = 'CHANGE_WORKOUT_TYPE';
export const CHANGE_VOICE_LANGUAGE = 'CHANGE_VOICE_LANGUAGE';
export const CHANGE_SCHEDULE = 'CHANGE_SCHEDULE';

export const changeWorkoutType = (newVal) => async (dispatch, getState) => {
  const oldVal = getState().currentUser.workout_type;

  dispatch({
    type: CHANGE_WORKOUT_TYPE,
    payload: {
      value: newVal
    }
  });

  try {
    await postUserSettings({ workout_type: newVal });
  } catch (e) {
    dispatch({
      type: CHANGE_WORKOUT_TYPE,
      payload: {
        value: oldVal
      }
    });
    return;
  }
  dispatch(resetQueue());
};

export const changeVoiceLanguage = (newVal) => async (dispatch, getState) => {
  const state = getState();
  const previousLanguageValue = state.currentUser.changing_voice;

  dispatch({
    type: CHANGE_VOICE_LANGUAGE,
    payload: {
      value: newVal
    }
  });

  if (!newVal) {
    dispatch(disableSwitchingVoice());
  } else if (state.isPlaying) {
    dispatch(enableSwitchingVoiceIfNeeded());
  }

  try {
    await postUserSettings({ changing_voice: newVal });
  } catch (e) {
    dispatch({
      type: CHANGE_VOICE_LANGUAGE,
      payload: {
        value: previousLanguageValue
      }
    });
  }
};

export const restoreVoiceLanguage = () => (dispatch, getState) => {
  const {
    currentUser: { previous_changing_voice: previousChaningVoice }
  } = getState();
  if (previousChaningVoice) {
    dispatch(changeVoiceLanguage(previousChaningVoice));
  }
};

export const changeSchedule = (fieldName, val) => async (dispatch) => {
  const cronValue = timeToCron(val);
  if (!cronValue) return;

  const updateObj = { [fieldName]: cronValue };

  dispatch({
    type: CHANGE_SCHEDULE,
    payload: {
      value: updateObj
    }
  });

  dispatch(cleanupSchedule());
  dispatch(possiblyEnableSchedule());

  await postUserSettings(updateObj);
};
