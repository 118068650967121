import * as PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { DEFAULT_LANGUAGE } from '../../config';
import withMeta from '../withMeta';
import RegisterCredentialsScreen from './RegisterCredentialsScreen';
import RegisterGreetingScreen from './RegisterGreetingScreen';
import RegisterInfoScreen from './RegisterInfoScreen';
import RegisterThankScreen from './RegisterThankYou';

const RegisterScreens = (props) => {
  const push = React.useCallback((url) => {
    props.history.push(`${props.match.url}/${url}`);
  }, []);

  React.useEffect(() => {
    if (props.submitSucceeded) {
      push('thank-you');
    }
  }, [props.submitSucceeded, push]);

  React.useEffect(
    () => () => {
      props.reset();
    },
    []
  );

  return (
    <Switch>
      <Route path="/register/" exact>
        <RegisterGreetingScreen language={props.language} target="/register/basic-info" />
      </Route>

      <Route path="/register/basic-info">
        <RegisterInfoScreen
          goToGreetingScreen={() => push('')}
          goToCredentialsScreen={() => push('credentials')}
          changeLanguage={props.changeLanguage}
          language={props.language}
        />
      </Route>

      <Route path="/register/credentials">
        <RegisterCredentialsScreen
          goToRegisterInfoScreen={() => push('basic-info')}
          onSubmit={props.onSubmit}
          language={props.language}
        />
      </Route>

      <Route path="/register/thank-you">
        <RegisterThankScreen email={props.email} target="/register/download" />
      </Route>

      <Route
        path="/register/download"
        component={() => {
          window.location.href = 'https://soundsuit.fm/download';
          return null;
        }}
      />

      <Route path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
};

RegisterScreens.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  email: PropTypes.string,
  submitSucceeded: PropTypes.bool,
  language: PropTypes.string
};

RegisterScreens.defaultProps = {
  email: null,
  submitSucceeded: false,
  language: DEFAULT_LANGUAGE
};

export default withRouter(withMeta(RegisterScreens, { title: 'register.meta.title' }));
