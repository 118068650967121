import { PLAY, PAUSE, DRAINED, SET_TRACK_PLAY_FAILED } from '../actions/player';
import { AnyAction } from 'redux';

export default function isPlaying(state = false, action: AnyAction) {
  switch (action.type) {
    case PLAY:
      return true;
    case PAUSE:
      return false;
    case DRAINED:
      return false;
    case SET_TRACK_PLAY_FAILED:
      return false;
    default:
      return state;
  }
}
