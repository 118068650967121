import { createSelector } from 'reselect';

const getCurrentTrack = (state) => state.activeTrack;
const getPlaybackData = (state) => state.playbackData;

const DEFAULT_VAL = {
  seek: 0,
  duration: 0
};

const currentPlaybackData = createSelector(
  [getCurrentTrack, getPlaybackData],
  (currentTrack, playbackData) => {
    if (!currentTrack || !playbackData[currentTrack]) {
      return { ...DEFAULT_VAL };
    }

    return {
      ...DEFAULT_VAL,
      ...playbackData[currentTrack]
    };
  }
);

export default currentPlaybackData;
