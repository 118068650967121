export const HOME_URL = process.env.REACT_APP_MAIN_HOST;

export const TERMS_AND_CONDITIONS_URL = {
  de: `${process.env.REACT_APP_MAIN_HOST}/de/agb`,
  en: `${process.env.REACT_APP_MAIN_HOST}/terms-and-conditions`
};

export const PRIVACY_AND_POLICY_URL = {
  de: `${process.env.REACT_APP_MAIN_HOST}/de/datenschutz`,
  en: `${process.env.REACT_APP_MAIN_HOST}/privacy-policy`
};

export const IMPRINT_URL = {
  en: `${process.env.REACT_APP_MAIN_HOST}/imprint`
};

export const CONTACT_URL = {
  en: `${process.env.REACT_APP_MAIN_HOST}/contact`
};

export const PACKAGE_VERSION = process.env.REACT_APP_VERSION;
export const PACKAGE_NAME = process.env.REACT_APP_NAME;

export const DEFAULT_FADE_INTERVAL = process.env.NODE_ENV === 'production' ? 24 : 8;
export const DEFAULT_FADE_STEP = 100; // ms
export const DEFAULT_VOLUME = 1;
export const DEFAULT_SWITCHING_VOICE_VOLUME = 1;

export const ENGLISH = 'en';

export enum LANGUAGES {
  english = 'en',
  german = 'de',
  french = 'fr'
}

export const DEFAULT_LANGUAGE = LANGUAGES.german;

export const getDefaultLanguage = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigatorLang = navigator.language || (navigator as any).userLanguage || '';
  return (
    Object.values(LANGUAGES).find((lng) => navigatorLang.toLowerCase().includes(lng)) ||
    DEFAULT_LANGUAGE
  );
};

export const APP_TYPES = {
  mrssporty: 'mrssporty',
  fitbox: 'fitbox',
  regular: 'regular'
};

export const SUBSCRIPTION_STATES = {
  trial: 'TRIAL',
  subscribed: 'SUBSCRIBED',
  paid: 'PAID'
};

export const MIN_PASSWORD_LENGTH = 6;
