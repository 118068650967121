import { error as showError } from 'react-notification-system-redux';

import {
  loadTracks,
  reset as resetQueue,
  TARGET_QUEUE_LENGTH,
  cleanup as cleanupQueue
} from './queue';

import getNextTrack from '../selectors/nextTrack';
import getSubsequentTracks from '../selectors/subsequentTracks';
import shouldSwitchToNext from '../selectors/shouldSwitchToNext';
import { shouldBePlayingNow } from '../selectors/schedule';
import { enableSwitchingVoiceIfNeeded, disableSwitchingVoice } from './switchingVoice';
import { possiblyEnableSchedule } from './schedule';
import { crossfadeInit, crossfadeStart, crossfadeStop, crossfadeDestroy } from './crossFader';

import detectAutoplay from '../lib/detectAutoplay';

import { like, unlike, addToLastPlayed } from '../services/api';

import i18n from '../i18n';

export const SET_ACTIVE_TRACK = 'SET_ACTIVE_TRACK';
export const SKIP_TO_NEXT = 'SKIP_TO_NEXT';
export const PLAY = 'PLAY';
export const PAUSE = 'PAUSE';
export const TRACK_ENDED = 'TRACK_ENDED';
export const DRAINED = 'DRAINED';
export const VOLUME_CHANGE = 'VOLUME_CHANGE';
export const SET_TRACK_DURATION = 'SET_TRACK_DURATION';
export const SET_TRACK_SEEK_POS = 'SET_TRACK_SEEK_POS';
export const SET_TRACK_LOAD_FAILED = 'SET_TRACK_LOAD_FAILED';
export const SET_TRACK_PLAY_FAILED = 'SET_TRACK_PLAY_FAILED';
export const SET_TRACK_LOADED = 'SET_TRACK_LOADED';
export const TRACK_LIKE = 'TRACK_LIKE';
export const TRACK_UNLIKE = 'TRACK_UNLIKE';
export const TRACK_MARKED_PLAYED = 'TRACK_MARKED_PLAYED';

export const setActiveTrack = (track) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_TRACK,
    payload: { track }
  });

  dispatch(cleanupQueue());
};

const nextTrack = () => (dispatch, getState) => {
  const track = getNextTrack(getState());

  const state = getState();

  if (track) {
    const tracksAhead = getSubsequentTracks(state).length;
    dispatch(crossfadeDestroy());
    dispatch(setActiveTrack(track));

    const numTracksToLoad = TARGET_QUEUE_LENGTH - tracksAhead;
    if (numTracksToLoad > 0) {
      dispatch(loadTracks(numTracksToLoad));
    }

    if (state.playbackData[track].loadFailed) {
      return dispatch(nextTrack());
    }

    return Promise.resolve();
  }

  // TODO: only if not loading
  // startup case
  return dispatch(loadTracks()).then(() =>
    // TODO: limit number of attempts
    dispatch(nextTrack())
  );
};

export const skipToNext = () => (dispatch) => {
  dispatch({ type: SKIP_TO_NEXT });
  dispatch(nextTrack());
};

export const play = () => (dispatch, getState) => {
  const state = getState();
  if (!state.activeTrack) return;

  dispatch({
    type: PLAY
  });

  dispatch(enableSwitchingVoiceIfNeeded());

  if (state.playbackData[state.activeTrack].loadFailed) {
    dispatch(nextTrack());
  }
};

export const startWithNewQueue = () => async (dispatch) => {
  dispatch(resetQueue());
  await dispatch(nextTrack());
  dispatch(play());
};

export const bootstrapped = () => async (dispatch, getState) => {
  dispatch(possiblyEnableSchedule());

  if (shouldBePlayingNow(getState()) && (await detectAutoplay())) {
    await dispatch(startWithNewQueue());
  } else {
    await dispatch(nextTrack());
  }
};

export const pause = () => (dispatch) => {
  dispatch({
    type: PAUSE
  });
  dispatch(disableSwitchingVoice());
  dispatch(crossfadeStop());
};

export const currentTrackEnded = () => nextTrack();

export const currentTrackFailed = () => nextTrack();

export const onVolumeChange = (volume) => ({
  type: VOLUME_CHANGE,
  payload: {
    volume
  }
});

export const setTrackLoaded = (id) => ({
  type: SET_TRACK_LOADED,
  payload: {
    id
  }
});

export const setTrackDuration = (id, duration) => ({
  type: SET_TRACK_DURATION,
  payload: {
    id,
    duration
  }
});

export const setTrackSeekPos = (id, seek) => (dispatch, getState) => {
  dispatch({
    type: SET_TRACK_SEEK_POS,
    payload: {
      id,
      seek
    }
  });

  const state = getState();

  if (state.isPlaying && shouldSwitchToNext(state) && !state.crossFader.interval) {
    if (!state.crossFader.progress.currentStep) {
      dispatch(crossfadeInit());
    }
    dispatch(crossfadeStart());
  }
};

export const setTrackLoadFailed = (id) => ({
  type: SET_TRACK_LOAD_FAILED,
  payload: { id }
});

export const setTrackPlayFailed = (id, message) => (dispatch) => {
  dispatch({
    type: SET_TRACK_PLAY_FAILED,
    payload: { id, message }
  });

  dispatch(
    showError({
      title: i18n.t('player.failedToPlay.title'),
      message: i18n.t('player.failedToPlay.content') + message
    })
  );
};

export const likeTrack = () => (dispatch, getState) => {
  const id = getState().activeTrack;

  like(id).then(() => {
    dispatch({
      type: TRACK_LIKE,
      payload: {
        id
      }
    });
  });
};

export const unlikeTrack = () => (dispatch, getState) => {
  const id = getState().activeTrack;

  unlike(id).then(() => {
    dispatch({
      type: TRACK_UNLIKE,
      payload: {
        id
      }
    });
  });
};

export const setTrackPlayed = (id) => (dispatch) => {
  addToLastPlayed(id).then(() => {
    dispatch({
      type: TRACK_MARKED_PLAYED,
      payload: {
        id
      }
    });
  });
};
