import { DEFAULT_FADE_INTERVAL, DEFAULT_FADE_STEP, getDefaultLanguage, LANGUAGES } from '../config';
import { SET_LANGUAGE } from '../actions/settings';
import { getLanguageFromUrl } from '../lib/locale';
import { AnyAction } from 'redux';

const langFromUrl = getLanguageFromUrl();

const defaultSettings = {
  fadeInterval: DEFAULT_FADE_INTERVAL,
  fadeStep: DEFAULT_FADE_STEP,
  language: langFromUrl || getDefaultLanguage(),
  languageOverride: !!langFromUrl
};

function settingsReducer(state = defaultSettings, action: AnyAction) {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload.value as LANGUAGES
      };
    default:
      return state;
  }
}

export default settingsReducer;
