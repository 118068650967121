import axios from './axios';

export function loadUserProfile() {
  return axios.get('/userinfo').then(({ data }) => data);
}

export function postLogout() {
  return axios.post('/logout').then(({ data }) => data);
}

export function postLogin(reqData) {
  return axios.post('/login', reqData).then(({ data }) => data);
}

export function postRegister(data) {
  return axios.post('/register', data);
}

export function postVerifyToken(token) {
  return axios.post(`/register/verify_token/${token}`);
}

export function postResetPassword(data) {
  return axios.post('/reset_password', data);
}

export function postNewPassword(reqData) {
  return axios.post('/reset_password/password', reqData);
}
