import React from 'react';
import { Field } from 'redux-form';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ConditionsLabelWrapper, FormRow } from '../shared/Form';
import component from '../util/renderComponents';

const AgreeToReceiveProductUpdates = ({ t, classes }) => (
  <FormRow smaller>
    <Field
      name="accept_receive_info"
      component={component.renderCheckbox}
      type="checkbox"
      label={<ConditionsLabelWrapper>{t('register.info_agreement')}</ConditionsLabelWrapper>}
      classes={{
        switchBase: classes.colorSwitchBase,
        checked: classes.colorChecked,
        bar: classes.colorBar
      }}
    />
  </FormRow>
);

AgreeToReceiveProductUpdates.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.shape().isRequired
};
export default withTranslation()(AgreeToReceiveProductUpdates);
