import React from 'react';
import { connect } from 'react-redux';
import Redirect from '../components/Redirect';
import { HOME_URL, LANGUAGES } from '../config';
import { IReduxStoreState } from '../types';

const SubscribeRedirect = ({ language }: { language: LANGUAGES }) => {
  let lng = '';

  switch (language) {
    case LANGUAGES.german:
      lng = '/de';
      break;
    case LANGUAGES.french:
      lng = '/fr';
  }

  return <Redirect target={`${HOME_URL || ''}${lng}/plans`} />;
};

export default connect((state: IReduxStoreState) => ({ language: state.settings.language }))(
  SubscribeRedirect
);
