import glamorous from 'glamorous';

import { btnReset } from './styles';
import { almostWhite, pinkPurple, pureWhite } from './colors';

const ActionButton = glamorous.button(
  {
    ...btnReset,
    width: '100%',
    borderRadius: '24px',
    padding: '10px',
    color: almostWhite,
    marginTop: '20px',
    transition: '0.3s color, 0.3s border-color',
    backgroundImage: 'linear-gradient(255deg,#ff9200 0%,#f41ef2 100%)'
  },
  ({ disabled, ...restProps }) => ({
    borderColor: disabled ? almostWhite : pinkPurple,
    color: disabled ? almostWhite : pureWhite,
    ...restProps
  })
);

export default ActionButton;
