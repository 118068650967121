import { postUserSettings } from '../services/api';
import { reload as resetQueue } from './queue';
import i18n from '../i18n';

export const CHANGE_MUSIC_STYLE = 'CHANGE_MUSIC_STYLE';
export const CHANGE_CUSTOMER_AGE = 'CHANGE_CUSTOMER_AGE';
export const CHANGE_BUSINESS_TYPE = 'CHANGE_BUSINESS_TYPE';
export const CHANGE_MUSIC_FLAVOR = 'CHANGE_MUSIC_FLAVOR';

export const SET_LANGUAGE = 'SET_LANGUAGE';

export const changeMusicStyle = (newVal) => async (dispatch, getState) => {
  const oldVal = getState().currentUser.music_style;

  dispatch({
    type: CHANGE_MUSIC_STYLE,
    payload: {
      value: newVal
    }
  });

  try {
    await postUserSettings({ music_style: newVal });
  } catch (e) {
    dispatch({
      type: CHANGE_MUSIC_STYLE,
      payload: {
        value: oldVal
      }
    });
    return;
  }

  dispatch(resetQueue());
};

export const changeBusinessType = (newVal) => async (dispatch, getState) => {
  const oldVal = getState().currentUser.business_type;

  dispatch({
    type: CHANGE_BUSINESS_TYPE,
    payload: {
      value: newVal
    }
  });

  try {
    await postUserSettings({ business_type: newVal });
  } catch (e) {
    dispatch({
      type: CHANGE_BUSINESS_TYPE,
      payload: {
        value: oldVal
      }
    });
    return;
  }
  dispatch(resetQueue());
};

export const changeMusicFlavor = (newVal) => async (dispatch, getState) => {
  const oldVal = getState().currentUser.music_flavors;

  dispatch({
    type: CHANGE_MUSIC_FLAVOR,
    payload: {
      value: newVal
    }
  });

  const newArray = getState().currentUser.music_flavors;

  try {
    await postUserSettings({ music_flavors: newArray });
  } catch (e) {
    dispatch({
      type: CHANGE_CUSTOMER_AGE,
      payload: {
        value: oldVal
      }
    });
    return;
  }
  dispatch(resetQueue());
};

export const changeCustomerAge = (newVal) => async (dispatch, getState) => {
  const oldVal = getState().currentUser.customer_age;

  const formattedValue = [`<${newVal}`];

  dispatch({
    type: CHANGE_CUSTOMER_AGE,
    payload: {
      value: formattedValue
    }
  });

  try {
    await postUserSettings({ customer_age: formattedValue });
  } catch (e) {
    dispatch({
      type: CHANGE_CUSTOMER_AGE,
      payload: {
        value: oldVal
      }
    });
    return;
  }
  dispatch(resetQueue());
};

const changeLanguage = (language, dispatch) => {
  i18n.changeLanguage(language);
  dispatch({
    type: SET_LANGUAGE,
    payload: {
      value: language
    }
  });
};

export const changeLanguageWithoutRequest = (language) => (dispatch) => {
  changeLanguage(language, dispatch);
};

export const setLanguage = (language) => (dispatch, getState) => {
  if (language !== getState().currentUser.language) {
    postUserSettings({ language });
  }
  changeLanguage(language, dispatch);
};
