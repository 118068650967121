/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import glamorous, { I, Span } from 'glamorous';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import { Field, reduxForm } from 'redux-form';

import { gradientBg225 } from './ui/styles';
import { orangeRed } from './ui/colors';

import BackButton from './ui/BackButton';
import ContainerIconButton from './ui/ContainerIconButton';
import ResetPasswordSuccessPage from './ResetPasswordSuccessPage';
import GradientBorderButton from './ui/GradientBorderButton';
import AuthForm from './ui/shared/Article';
import IconButton from './ui/IconButton';
import withMeta from './withMeta';

const Container = glamorous.main({
  ...gradientBg225,
  minHeight: '100%',
  minWidth: '100%',
  height: '100%',
  display: 'flex',
  maxHeight: '725px'
});

const Form = glamorous.form({
  padding: '24px'
});

const Header = glamorous.h1({
  padding: '24px'
});

const LoginError = glamorous.p({
  color: 'white',
  backgroundColor: orangeRed,
  borderRadius: '12px',
  margin: '20px auto',
  padding: '5px 30px',
  fontWeight: 600,
  textAlign: 'center',
  fontSize: '14px'
});

const FormRow = glamorous.div(
  {
    margin: '0 auto 15px'
  },
  ({ smaller }) => ({
    maxWidth: smaller ? '680px' : null
  })
);

const renderField = ({ input, label, type, meta: { touched, error }, ...custom }) => (
  <TextField
    error={touched && !!error}
    {...input}
    type={type}
    label={label}
    fullWidth
    {...custom}
  />
);

const validate = ({ email }) => {
  const errors = {};

  if (!email) {
    errors.email = 'required';
  }

  return errors;
};

class ForgotPasswordPage extends Component {
  render() {
    const { invalid, submitting, error, submitSucceeded, currentEmail, t } = this.props;

    if (submitSucceeded) {
      return <ResetPasswordSuccessPage email={currentEmail} />;
    }

    return (
      <Container>
        <AuthForm>
          <Header>
            <ContainerIconButton>
              <IconButton active>
                <I className="icon icon-soundsuit" fontSize="48px" />
              </IconButton>
            </ContainerIconButton>
            <Span marginBottom="24px" marginRight="24px">
              <BackButton>
                <I className="fa fa-chevron-left" fontSize="24px" />
              </BackButton>
            </Span>

            {t('forgot.title')}
          </Header>

          <Form onSubmit={this.props.handleSubmit}>
            <FormRow>
              <Field
                required
                name="email"
                type="email"
                component={renderField}
                label={t('forgot.email.label')}
              />
            </FormRow>

            <GradientBorderButton disabled={submitting || invalid}>
              {t('forgot.button')}
            </GradientBorderButton>

            {error && (
              <LoginError>
                {error.code
                  ? t(`forgot.errors.${error.code}`) || t('unknownError')
                  : t('unknownError')}
              </LoginError>
            )}
          </Form>
        </AuthForm>
      </Container>
    );
  }
}

export default reduxForm({
  validate,
  form: 'forgotPassword'
})(withTranslation()(withMeta(ForgotPasswordPage, { title: 'forgot.meta.title' })));
