import { AnyAction } from 'redux';
import { VOLUME_CHANGE } from '../actions/player';
import { DEFAULT_VOLUME } from '../config';

export default function player(state = DEFAULT_VOLUME, action: AnyAction) {
  switch (action.type) {
    case VOLUME_CHANGE:
      return action.payload.volume;
    default:
      return state;
  }
}
