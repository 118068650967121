// @flow
import { SET_ACTIVE_TRACK, DRAINED } from '../actions/player';
import { AnyAction } from 'redux';

export default function player(state = null, action: AnyAction) {
  switch (action.type) {
    case SET_ACTIVE_TRACK:
      return action.payload.track;
    case DRAINED:
      return null;
    default:
      return state;
  }
}
