import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import transEn from './locales/en/translations.json';
import transDe from './locales/de/translations.json';
import transFr from './locales/fr/translations.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: transEn
    },
    de: {
      translations: transDe
    },
    fr: {
      translations: transFr
    }
  },
  lng: 'de',
  fallbackLng: 'de',

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  debug: process.env.NODE_ENV !== 'production',

  interpolation: {
    escapeValue: false
  },

  react: {
    wait: true
  }
});

export default i18n;
