import axios from 'axios';
import trialExpiredInterceptor from './trialExpiredInterceptor';
import { HOME_URL, PACKAGE_NAME, PACKAGE_VERSION } from '../config';

const client = axios.create({
  baseURL: HOME_URL ? `${HOME_URL}/api/` : '/api',
  timeout: 60000,
  withCredentials: true,
  headers: { 'x-client-id': `${PACKAGE_NAME}-${PACKAGE_VERSION}` },
  xsrfCookieName: '__csrf',
  xsrfHeaderName: 'x-csrf-token'
});

client.interceptors.response.use(...trialExpiredInterceptor);
client.interceptors.response.use((response) => {
  if (response.headers['x-csrf-token']) {
    document.cookie = `__csrf=${response.headers['x-csrf-token']}`;
  }
  return response;
});

export default client;
