import React from 'react';
import glamorous from 'glamorous';

import PropTypes from 'prop-types';
import { darkGrey, pureWhite } from '../colors';
import mediaQueries from './mediaQueries';

const article = {
  maxWidth: '700px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '12px',
  minHeight: '70vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'hidden',
  [mediaQueries.phone]: {
    maxWidth: '100%',
    maxHeight: '100%',
    height: '100%',
    border: 'none',
    borderRadius: '0',
    margin: 'unset'
  }
};
const WhiteArticle = glamorous.article(
  {
    ...article,
    borderColor: darkGrey,
    background: pureWhite
  },
  ({ hasBottomNeighbour }) => ({
    margin: hasBottomNeighbour ? '15vh auto 5px auto' : 'auto'
  })
);

const TransparentArticle = glamorous.article(
  {
    ...article,
    background: 'transparent',
    borderColor: pureWhite
  },
  ({ hasBottomNeighbour }) => ({
    margin: hasBottomNeighbour ? '15vh auto 5px auto' : 'auto'
  })
);

export const ArticleContainer = glamorous.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'hidden'
});

const Article = ({ isTransparent, hasBottomNeighbour, children }) => {
  if (isTransparent) {
    return (
      <TransparentArticle hasBottomNeighbour={hasBottomNeighbour}>{children}</TransparentArticle>
    );
  }
  return <WhiteArticle hasBottomNeighbour={hasBottomNeighbour}>{children}</WhiteArticle>;
};

Article.propTypes = {
  isTransparent: PropTypes.bool,
  hasBottomNeighbour: PropTypes.bool,
  children: PropTypes.node.isRequired
};
Article.defaultProps = {
  isTransparent: false,
  hasBottomNeighbour: false
};

export default Article;
