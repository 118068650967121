import later from 'later';

import { startWithNewQueue, pause } from './player';
import { postUserSettings } from '../services/api';
import { CHANGE_SCHEDULE } from './settingsSporty';

export const ENABLE_SCHEDULE = 'ENABLE_SCHEDULE';
export const CLEANUP_SCHEDULE = 'CLEANUP_SCHEDULE';
export const TICK_SCHEDULE_START = 'TICK_SCHEDULE_START';
export const TICK_SCHEDULE_STOP = 'TICK_SCHEDULE_STOP';

export const cleanupSchedule = () => (dispatch, getState) => {
  const { schedule } = getState();

  if (schedule.startInterval) {
    schedule.startInterval.clear();
  }
  if (schedule.stopInterval) {
    schedule.stopInterval.clear();
  }

  dispatch({
    type: CLEANUP_SCHEDULE
  });
};

export const startPlayingBySchedule = () => (dispatch, getState) => {
  const { isPlaying } = getState();

  setTimeout(() => {
    dispatch({
      type: TICK_SCHEDULE_START
    });
  }, 1100);

  if (isPlaying) return;

  dispatch(startWithNewQueue());
};

export const stopPlayingBySchedule = () => (dispatch, getState) => {
  const { isPlaying } = getState();

  setTimeout(() => {
    dispatch({
      type: TICK_SCHEDULE_STOP
    });
  }, 1100);

  if (!isPlaying) return;

  dispatch(pause());
};

export const possiblyEnableSchedule = () => (dispatch, getState) => {
  const { currentUser } = getState();

  if (
    currentUser &&
    currentUser.schedule_start &&
    currentUser.schedule_stop &&
    currentUser.schedule_enabled
  ) {
    dispatch(cleanupSchedule());

    later.date.localTime();

    const startInterval = later.setInterval(
      () => dispatch(startPlayingBySchedule()),
      later.parse.cron(currentUser.schedule_start)
    );

    const stopInterval = later.setInterval(
      () => dispatch(stopPlayingBySchedule()),
      later.parse.cron(currentUser.schedule_stop)
    );

    dispatch({
      type: ENABLE_SCHEDULE,
      payload: {
        startInterval,
        stopInterval
      }
    });
  }
};

export const toggleScheduleEnabled = (val) => async (dispatch) => {
  const updateObj = { schedule_enabled: val };

  dispatch({
    type: CHANGE_SCHEDULE,
    payload: {
      value: updateObj
    }
  });

  dispatch(cleanupSchedule());
  dispatch(possiblyEnableSchedule());

  await postUserSettings(updateObj);
};
