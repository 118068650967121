import { combineReducers } from 'redux';
import omit from 'lodash/omit';
import without from 'lodash/without';

import { LOADING_TRACKS_SUCCESS, DELETE_ITEMS } from '../actions/queue';
import { TRACK_LIKE, TRACK_UNLIKE } from '../actions/player';

function addTracksById(state, action) {
  // TODO: normalizr
  const newState = { ...state };
  action.payload.tracks.forEach((t) => {
    newState[t._id] = {
      ...t
    };
  });
  return newState;
}

function setTrackLike(state, action) {
  const newState = {
    ...state,
    [action.payload.id]: {
      ...state[action.payload.id],
      isLiked: true
    }
  };

  return newState;
}

function setTrackUnlike(state, action) {
  const newState = {
    ...state,
    [action.payload.id]: {
      ...state[action.payload.id],
      isLiked: false
    }
  };

  return newState;
}

function byId(state = {}, action) {
  switch (action.type) {
    case LOADING_TRACKS_SUCCESS:
      return addTracksById(state, action);
    case TRACK_LIKE:
      return setTrackLike(state, action);
    case TRACK_UNLIKE:
      return setTrackUnlike(state, action);
    case DELETE_ITEMS:
      return omit(state, action.payload.items);
    default:
      return state;
  }
}

function allIds(state = [], action) {
  switch (action.type) {
    case LOADING_TRACKS_SUCCESS:
      return [...state, ...action.payload.tracks.map((t) => t._id)];
    case DELETE_ITEMS:
      return without(state, ...action.payload.items);
    default:
      return state;
  }
}

export default combineReducers({
  byId,
  allIds
});
