import glamorous from 'glamorous';
import { gradientBg225 } from '../../ui/styles';
import { black, lightGrey, orangeRed } from '../../ui/colors';

const Form = glamorous.form({
  padding: '0px 23px 0px 24px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column'
});

const Container = glamorous.main({
  ...gradientBg225,
  minHeight: '100%',
  minWidth: '100%',
  height: '100%',
  display: 'flex',
  maxHeight: '725px'
});

const Header = glamorous.h1({
  padding: '0.5vh 24px 12px 24px'
});

const styles = (theme) => ({
  icon: {
    marginLeft: theme.spacing.unit * 2,
    color: lightGrey,
    fontSize: '1.2em',
    transition: '0.3s color',
    '&:hover': {
      color: black
    }
  },
  stepper: {
    background: 'transparent',
    justifyContent: 'center'
  },
  dot: {
    marginTop: '1px',
    marginBottom: '1px',
    '&:not(:first-child)': {
      marginLeft: '5px'
    }
  }
});

const FormRow = glamorous.div(
  {
    marginBottom: '10px'
  },
  ({ smaller }) => ({
    maxWidth: smaller ? '680px' : null
  })
);

const LoginError = glamorous.p({
  color: 'white',
  backgroundColor: orangeRed,
  borderRadius: '12px',
  margin: '20px auto',
  padding: '5px 30px',
  fontWeight: 600,
  textAlign: 'center',
  fontSize: '14px'
});
const ConditionsLabelWrapper = glamorous.div({
  fontSize: '2vh'
});

export { Form, Container, Header, FormRow, styles, LoginError, ConditionsLabelWrapper };
