import { next } from '../services/api';

import getSubsequentTracks from '../selectors/subsequentTracks';
import getTracksToCleanup from '../selectors/getTracksToCleanup';

export const TARGET_QUEUE_LENGTH = 5;

export const LOADING_TRACKS = 'LOADING_TRACKS';
export const LOADING_TRACKS_SUCCESS = 'LOADING_TRACKS_SUCCESS';
export const DELETE_ITEMS = 'DELETE_ITEMS';

const useFakeSounds = process.env.NODE_ENV !== 'production';

export const loadTracks = (count = TARGET_QUEUE_LENGTH) => (dispatch, getState) => {
  // TODO: don't allow concurrent request (Sagas?)
  dispatch({
    type: LOADING_TRACKS
  });

  return next(count, getState().queue.allIds).then((tracks) => {
    dispatch({
      type: LOADING_TRACKS_SUCCESS,
      payload: {
        tracks: useFakeSounds
          ? tracks.map((track) => ({
              ...track,
              file: 'https://khoanguyen.me/react-howler/sound2.ogg'
            }))
          : tracks
      }
    });
  });
};

export const reset = () => (dispatch, getState) => {
  const subsequentTracks = getSubsequentTracks(getState());
  dispatch({
    type: DELETE_ITEMS,
    payload: {
      items: subsequentTracks
    }
  });
};

export const reload = () => async (dispatch) => {
  dispatch(reset());
  await dispatch(loadTracks());
};

export const cleanup = () => (dispatch, getState) => {
  const tracksToCleanup = getTracksToCleanup(getState());
  dispatch({
    type: DELETE_ITEMS,
    payload: {
      items: tracksToCleanup
    }
  });
};
