import React from 'react';
import glamorous from 'glamorous';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { container, gradientText } from './ui/styles';
import { pinkPurple } from './ui/colors';

const Container = glamorous.article({
  ...container
});

const CheckIcon = glamorous.i({
  ...gradientText,
  fontSize: '60px',
  marginBottom: '7vh'
});

const Title = glamorous.h1({
  margin: '15vh 0 7vh'
});

const PageInner = glamorous.main({
  padding: '24px',
  textAlign: 'center'
});

const Email = glamorous.span({
  color: pinkPurple,
  fontWeight: 'bold'
});

const Paragraph = glamorous.p({
  marginBottom: '3vh',
  lineHeight: 1.5
});

const ResetPasswordSuccessPage = ({ email, t }) => (
  <Container>
    <PageInner>
      <Title>{t('forgot.success.thankYou')}</Title>

      <CheckIcon className="fa fa-check" />

      <Paragraph>
        {t('forgot.success.email')}
        <br />
        <Email>{email}</Email>
      </Paragraph>

      <Paragraph>{t('forgot.success.emailCheck')}</Paragraph>
    </PageInner>
  </Container>
);

ResetPasswordSuccessPage.propTypes = {
  email: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(ResetPasswordSuccessPage);
