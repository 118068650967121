import { createSelector } from 'reselect';

const getCurrentTrack = (state) => state.activeTrack;

const getQueue = (state) => state.queue.allIds;

const getTracksToCleanup = createSelector([getCurrentTrack, getQueue], (currentTrack, queue) => {
  if (!currentTrack) return [];
  const index = queue.indexOf(currentTrack);
  const unneeded = queue.slice(0, Math.max(0, index - 10));
  return unneeded;
});

export default getTracksToCleanup;
