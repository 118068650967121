import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const defaultMeta = {
  title: 'general.defaultTitle'
};
const withMeta = (WrappedComponent, providedMeta = {}) => {
  const meta = {
    ...defaultMeta,
    ...providedMeta
  };

  return connect(({ settings: { language: lang } }) => ({
    lang
  }))(({ lang, ...props }) => (
    <React.Fragment>
      <Helmet htmlAttributes={{ lang }}>
        <meta charSet="utf-8" />
        <title>{useTranslation().t(meta.title)}</title>
      </Helmet>
      <WrappedComponent {...props} />
    </React.Fragment>
  ));
};

export default withMeta;
