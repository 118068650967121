/* eslint-disable react/prop-types */
import TextField from '@material-ui/core/TextField';
import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const renderField = ({ input, label, type, meta: { touched, error }, ...custom }) => (
  <TextField
    error={touched && !!error}
    {...input}
    type={type}
    label={label}
    fullWidth
    {...custom}
  />
);

const renderCheckbox = ({ input, label, type, name, value, classes }) => (
  <FormControlLabel
    control={
      <Switch
        {...input}
        type={type}
        value={name}
        checked={value}
        color="secondary"
        classes={classes}
      />
    }
    label={label}
  />
);

export default { renderField, renderCheckbox };
