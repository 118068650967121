import { createSelector } from 'reselect';

import getCurrentPlaybackData from './currentPlaybackData';

const getSettings = (state) => state.settings;

const shouldSwitchToNext = createSelector(
  getSettings,
  getCurrentPlaybackData,
  ({ fadeInterval }, { seek, duration }) => {
    if (seek === 0 || duration === 0) return false;
    return duration - seek < fadeInterval;
  }
);

export default shouldSwitchToNext;
