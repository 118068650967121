import React from 'react';
import { Div } from 'glamorous';
import MobileStepper from '@material-ui/core/MobileStepper';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import GradientBorderButton from '../../ui/GradientBorderButton';
import { styles } from '../shared/Form';

const RegisterMobileStepper = (props) => (
  <Div marginTop="auto" marginBottom="24px">
    <MobileStepper
      variant="dots"
      steps={3}
      position="static"
      activeStep={props.activeStep}
      className={props.classes.stepper}
      classes={{
        dot: props.classes.dot
      }}
    />
    <GradientBorderButton
      disabled={props.submitting || props.invalid}
      lineHeight="1.4"
      fontSize="1.25rem"
      type="button"
    >
      {props.t(`register.${props.text}`)}
    </GradientBorderButton>
    {props.children}
  </Div>
);

RegisterMobileStepper.propTypes = {
  t: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
  classes: PropTypes.shape({
    dot: PropTypes.string.isRequired,
    stepper: PropTypes.string.isRequired
  }).isRequired,
  children: PropTypes.node,
  text: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool
};

RegisterMobileStepper.defaultProps = {
  children: null,
  submitting: false,
  invalid: false
};

export default withTranslation()(withStyles(styles)(RegisterMobileStepper));
