import React, { Component } from 'react';
import glamorous, { I, Div } from 'glamorous';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import { gradientBg } from './ui/styles';

const Container = glamorous.main({
  ...gradientBg,
  color: '#fff',
  minHeight: '100%',
  minWidth: '100%'
});

const Content = glamorous.div({
  padding: '5%',
  paddingTop: '30%'
});

class LoadingScreen extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  render() {
    return (
      <Container>
        <Content>
          <h1>{this.props.t('welcome_screen.greeting')}</h1>
          <Div marginTop="10px">
            <I className="icon icon-soundsuit" fontSize="48px" />
          </Div>

          <div className="loader" />
        </Content>
      </Container>
    );
  }
}

export default withTranslation()(LoadingScreen);
