import omit from 'lodash/omit';

import {
  SET_TRACK_DURATION,
  SET_TRACK_SEEK_POS,
  SET_TRACK_LOAD_FAILED,
  SET_TRACK_LOADED,
  TRACK_MARKED_PLAYED,
  SET_TRACK_PLAY_FAILED
} from '../actions/player';
import { DELETE_ITEMS, LOADING_TRACKS_SUCCESS } from '../actions/queue';
import { AnyAction } from 'redux';

function setDurationForItem(
  state: Record<string, { duration: number }>,
  { payload: { id, duration } }: AnyAction
) {
  const newState = { ...state };
  newState[id] = {
    ...state[id],
    duration
  };
  return newState;
}

function setTrackLoadedForItem(
  state: Record<string, { loaded?: boolean }>,
  { payload: { id } }: AnyAction
) {
  const newState = { ...state };
  newState[id] = {
    ...state[id],
    loaded: true
  };
  return newState;
}

function setLoadFailedForItem(
  state: Record<string, { loadFailed?: boolean }>,
  { payload: { id } }: AnyAction
) {
  const newState = { ...state };
  newState[id] = {
    ...state[id],
    loadFailed: true
  };
  return newState;
}

function setPlayFailedForItem(
  state: Record<string, { playFailed?: boolean; playFailedReason?: string }>,
  { payload: { id, message } }: AnyAction
) {
  const newState = { ...state };
  newState[id] = {
    ...state[id],
    playFailed: true,
    playFailedReason: message
  };
  return newState;
}

function setTrackMarkedPlayedForItem(
  state: Record<string, { markedPlayed?: boolean }>,
  { payload: { id } }: AnyAction
) {
  const newState = { ...state };
  newState[id] = {
    ...state[id],
    markedPlayed: true
  };
  return newState;
}

function setSeekForItem(
  state: Record<string, { seek?: number }>,
  { payload: { id, seek } }: AnyAction
) {
  const newState = { ...state };
  newState[id] = {
    ...state[id],
    seek
  };
  return newState;
}

function addTracksById(state: Record<string, unknown>, action: AnyAction) {
  const newState = { ...state };
  action.payload.tracks.forEach(({ id }: { id: string }) => {
    newState[id] = newState[id] || {};
  });
  return newState;
}

export default function playbackData(state = {}, action: AnyAction) {
  switch (action.type) {
    case SET_TRACK_DURATION:
      return setDurationForItem(state, action);
    case SET_TRACK_SEEK_POS:
      return setSeekForItem(state, action);
    case LOADING_TRACKS_SUCCESS:
      return addTracksById(state, action);
    case DELETE_ITEMS:
      return omit(state, action.payload.items);
    case SET_TRACK_LOAD_FAILED:
      return setLoadFailedForItem(state, action);
    case SET_TRACK_PLAY_FAILED:
      return setPlayFailedForItem(state, action);
    case SET_TRACK_LOADED:
      return setTrackLoadedForItem(state, action);
    case TRACK_MARKED_PLAYED:
      return setTrackMarkedPlayedForItem(state, action);
    default:
      return state;
  }
}
