import React from 'react';

import glamorous from 'glamorous';

import PropTypes from 'prop-types';
import { btnReset } from './styles';
import { almostWhite, pinkPurple, pureWhite, tangerine } from './colors';

const gradient = `linear-gradient(to right, ${pinkPurple}, ${tangerine})`;

const GradientBorderButtonText = glamorous.span(
  {
    color: pureWhite,
    transition: '0.3s color, 0.3s background'
  },
  ({ hasGradient, fontSize }) => ({
    background: hasGradient ? gradient : pureWhite,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: fontSize || '100%'
  })
);

const GradientBorderButtonInner = glamorous.button(
  {
    ...btnReset,
    width: '100%',
    color: pureWhite,
    padding: '10px',
    transition: '0.3s color, 0.3s background',
    background: 'transparent',
    borderRadius: '24px',
    type: 'button'
  },
  ({ hasGradient }) => ({
    background: hasGradient ? pureWhite : gradient,
    color: hasGradient ? pureWhite : pureWhite
  })
);

const GradientBorderButtonWrapper = glamorous.div(
  {
    ...btnReset,
    width: '100%',
    borderRadius: '24px',
    padding: '2px',
    marginTop: '20px',
    background: gradient,
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0)',
    transition: '0.3s color, 0.3s background, 0.3s box-shadow',
    ':hover': {
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.25)'
    }
  },
  ({ hasGradient }) => ({
    background: hasGradient ? gradient : gradient,
    color: hasGradient ? almostWhite : pureWhite
  })
);

const GradientBorderButton = ({ children, disabled, hasGradient, ...restProps }) => (
  <GradientBorderButtonWrapper hasGradient={hasGradient || disabled}>
    <GradientBorderButtonInner hasGradient={hasGradient || disabled}>
      <GradientBorderButtonText
        type="button"
        hasGradient={hasGradient || disabled}
        disabled={disabled}
        {...restProps}
      >
        {children}
      </GradientBorderButtonText>
    </GradientBorderButtonInner>
  </GradientBorderButtonWrapper>
);

GradientBorderButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  hasGradient: PropTypes.bool,
  children: PropTypes.node.isRequired
};

GradientBorderButton.defaultProps = {
  hasGradient: false
};
export default GradientBorderButton;
