import { createSelector } from 'reselect';

const getCurrentTrack = (state) => state.activeTrack;

const getQueue = (state) => state.queue.allIds;

const getNextTrack = createSelector([getCurrentTrack, getQueue], (currentTrack, queue) => {
  if (!currentTrack) return queue[0];
  const index = queue.indexOf(currentTrack);
  const next = queue[index + 1];
  return next;
});

export default getNextTrack;
