import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import InputLabel from '@material-ui/core/InputLabel';
import LanguageSelect from '../../language/LanguageSelect';

const LanguagePickerContainer = glamorous.div({
  display: 'flex',
  flexFlow: 'column'
});

const LanguagePicker = ({ t, input: { value, onChange } }) => (
  <LanguagePickerContainer>
    <InputLabel shrink>{t('language.label')}</InputLabel>
    <LanguageSelect value={value} onChange={onChange} />
  </LanguagePickerContainer>
);

LanguagePicker.propTypes = {
  t: PropTypes.func.isRequired,
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired
};

export default withTranslation()(LanguagePicker);
