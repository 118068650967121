import { createSelector } from 'reselect';
import get from 'lodash/get';
import later from 'later';

const getScheduleEnabled = (state) => state.schedule.enabled;
const getLastTickStart = (state) => state.schedule.lastTickStart;
const getLastTickStop = (state) => state.schedule.lastTickStop;
const getCronStart = (state) => get(state.currentUser, 'schedule_start');
const getCronStop = (state) => get(state.currentUser, 'schedule_stop');

export const willTickStart = createSelector(
  [getScheduleEnabled, getCronStart, getLastTickStart],
  (scheduleEnabled, cronStart) => {
    if (!scheduleEnabled) return null;
    return later.schedule(later.parse.cron(cronStart)).next();
  }
);

export const willTickStop = createSelector(
  [getScheduleEnabled, getCronStop, getLastTickStop],
  (scheduleEnabled, cronStop) => {
    if (!scheduleEnabled) return null;
    return later.schedule(later.parse.cron(cronStop)).next();
  }
);

export const shouldBePlayingNow = createSelector(
  [willTickStart, willTickStop],
  (tickStart, tickStop) => tickStop < tickStart
);
