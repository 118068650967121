export const INIT_CROSSFADE = 'INIT_CROSSFADE';
export const START_CROSSFADE = 'START_CROSSFADE';
export const STOP_CROSSFADE = 'STOP_CROSSFADE';
export const TICK_CROSSFADE = 'TICK_CROSSFADE';
export const DESTROY_CROSSFADE = 'DESTROY_CROSSFADE';

export const crossfadeStop = () => (dispatch, getState) => {
  const {
    crossFader: { interval }
  } = getState();
  clearInterval(interval);
  dispatch({
    type: STOP_CROSSFADE
  });
};

export const crossfadeDestroy = () => (dispatch) => {
  dispatch(crossfadeStop());

  dispatch({
    type: DESTROY_CROSSFADE
  });
};

const fadeStepRun = () => (dispatch, getState) => {
  dispatch({
    type: TICK_CROSSFADE
  });

  const {
    crossFader: {
      progress: { totalSteps, currentStep }
    }
  } = getState();
  const fadeComplete = currentStep >= totalSteps;

  if (fadeComplete) {
    dispatch(crossfadeDestroy());
  }
};

export const crossfadeStart = () => (dispatch, getState) => {
  const {
    settings: { fadeStep }
  } = getState();

  dispatch({
    type: START_CROSSFADE,
    payload: {
      interval: setInterval(() => dispatch(fadeStepRun()), fadeStep)
    }
  });
};

export const crossfadeInit = () => (dispatch, getState) => {
  const {
    settings: { fadeInterval, fadeStep }
  } = getState();

  dispatch({
    type: INIT_CROSSFADE,
    payload: {
      fadeInterval,
      fadeStep
    }
  });
};
