import { MIN_PASSWORD_LENGTH } from '../../../config';

const validate = ({ company_name: companyName, email, password, accept_tos: acceptTos }) => {
  const errors = {};

  if (!companyName) {
    errors.company_name = 'required';
  }

  if (!email) {
    errors.email = 'required';
  }

  if (!password || password.length < MIN_PASSWORD_LENGTH) {
    errors.password = 'required';
  }

  if (!acceptTos) {
    errors.accept_tos = 'required';
  }

  return errors;
};

export default validate;
