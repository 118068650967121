import { loadProfile } from './auth';
import i18n from '../i18n';

export const INITIALIZE = 'INITIALIZE';
export const INITIALIZE_COMPLETE = 'INITIALIZE_COMPLETE';

export const init = () => async (dispatch, getState) => {
  dispatch({
    type: INITIALIZE
  });

  i18n.changeLanguage(getState().settings.language);

  try {
    await dispatch(loadProfile());
  } catch (error) {
    // do nothing
  } finally {
    dispatch({
      type: INITIALIZE_COMPLETE
    });
  }
};
