import { success } from 'react-notification-system-redux';
import isMatch from 'lodash/isMatch';
import { postUserSettings } from '../services/api';
import i18n from '../i18n';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const updateProfile = (updateObject) => async (dispatch, getState) => {
  const { currentUser } = getState();

  if (isMatch(currentUser, updateObject)) {
    // nothing to save
    return;
  }

  await postUserSettings(updateObject);
  dispatch({
    type: UPDATE_PROFILE,
    payload: {
      value: updateObject
    }
  });
  dispatch(
    success({
      title: i18n.t('general.notificationSaved')
    })
  );
};
