import React from 'react';
import { withTranslation } from 'react-i18next';
import { I } from 'glamorous';

import PropTypes from 'prop-types';
import IconButton from '../ui/IconButton';
import RegisterTemplate from './RegisterTemplate';
import GradientBorderButton from '../ui/GradientBorderButton';
import { CheckIcon, DescriptionText, Email, GreetingBlackTitle } from './RegisterTemplateItems';

const iconButton = (
  <IconButton active>
    <I className="icon icon-soundsuit" fontSize="48px" />
  </IconButton>
);

const greetingTitle = (t) => (
  <GreetingBlackTitle>{t('register.success.thankYou')}</GreetingBlackTitle>
);

const description = (t, email) => (
  <React.Fragment>
    <CheckIcon className="fa fa-check" />
    <DescriptionText>{t('register.success.email')}</DescriptionText>
    <DescriptionText>
      <Email>{email}</Email>
    </DescriptionText>
    <DescriptionText>{t('register.success.emailCheck')}</DescriptionText>
  </React.Fragment>
);

const button = (t) => (
  <GradientBorderButton disabled={false} lineHeight="1.4" fontSize="1.25rem" type="button">
    {t('register.success.button')}
  </GradientBorderButton>
);
const RegisterThankYou = ({ email, t, target }) => (
  <RegisterTemplate
    iconButton={iconButton}
    greetingTitle={greetingTitle(t)}
    description={description(t, email)}
    button={button(t)}
    target={target}
  />
);

RegisterThankYou.propTypes = {
  email: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  target: PropTypes.string.isRequired
};

export default withTranslation()(RegisterThankYou);
