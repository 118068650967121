import React from 'react';
import { withTranslation } from 'react-i18next';
import { I } from 'glamorous';

import PropTypes from 'prop-types';
import IconButton from '../ui/IconButton';
import RegisterTemplateNoForm from './RegisterTemplate';
import GradientBorderButton from '../ui/GradientBorderButton';
import { DescriptionText, GreetingWhiteTitle } from './RegisterTemplateItems';
import LegalLinks from './LegalLinks';

const iconButton = (
  <IconButton white active={false}>
    <I className="icon icon-soundsuit" fontSize="48px" />
  </IconButton>
);

const greetingTitle = (t) => <GreetingWhiteTitle>{t('register.greetingTitle')}</GreetingWhiteTitle>;

const description = (t) =>
  t('register.greetingDescription', { returnObjects: true }).map((text) => (
    <DescriptionText key={text}>{text}</DescriptionText>
  ));

const button = (t) => (
  <GradientBorderButton
    disabled={false}
    hasGradient
    lineHeight="1.4"
    fontSize="1.25rem"
    type="button"
  >
    {t('register.next')}
  </GradientBorderButton>
);

const RegisterGreetingScreen = ({ t, language, target }) => (
  <RegisterTemplateNoForm
    isTransparent
    hasBottomNeighbour
    iconButton={iconButton}
    greetingTitle={greetingTitle(t)}
    description={description(t)}
    button={button(t)}
    target={target}
  >
    <LegalLinks language={language} />
  </RegisterTemplateNoForm>
);

RegisterGreetingScreen.propTypes = {
  t: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired
};

export default withTranslation()(RegisterGreetingScreen);
