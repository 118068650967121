import { AnyAction } from 'redux';
import { CLEANUP_AUTH_REDIRECT, TRIAL_EXPIRED_API_ERROR } from '../actions/auth';

function redirectTo(state = null, action: AnyAction) {
  switch (action.type) {
    case TRIAL_EXPIRED_API_ERROR:
      return '/subscribe';
    case CLEANUP_AUTH_REDIRECT:
      return null;
    default:
      return state;
  }
}

export default redirectTo;
