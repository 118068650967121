import { combineReducers, AnyAction } from 'redux';

import {
  INIT_CROSSFADE,
  START_CROSSFADE,
  STOP_CROSSFADE,
  TICK_CROSSFADE,
  DESTROY_CROSSFADE
} from '../actions/crossFader';

interface IState {
  readonly currentStep?: number;
  readonly totalSteps?: number;
}
function progress(state: IState = {}, action: AnyAction): IState {
  switch (action.type) {
    case INIT_CROSSFADE:
      return {
        totalSteps: Math.ceil((action.payload.fadeInterval * 1000) / action.payload.fadeStep),
        currentStep: 0
      };
    case TICK_CROSSFADE:
      return {
        ...state,
        currentStep: (state.currentStep || 0) + 1
      };
    case DESTROY_CROSSFADE:
      return {};
    default:
      return state;
  }
}

function interval(state: null | number = null, action: AnyAction): null | number {
  switch (action.type) {
    case START_CROSSFADE:
      return action.payload.interval;
    case STOP_CROSSFADE:
      return null;
    default:
      return state;
  }
}

export default combineReducers({
  interval,
  progress
});
