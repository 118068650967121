import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Provider, connect } from 'react-redux';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Notifications from 'react-notification-system-redux';
import PropTypes from 'prop-types';

import moment from 'moment';
import momentDurationFormat from 'moment-duration-format';

import '@fortawesome/fontawesome-free/css/all.css';
import 'typeface-open-sans';

import '../index.css';
import '../i18n';

import Router from './Router';
import * as authActions from '../actions/auth';
import store from '../store';
import theme from '../lib/theme';

import LoadingScreen from '../components/LoadingScreen';

momentDurationFormat(moment);

class Root extends Component {
  static propTypes = {
    initComplete: PropTypes.bool.isRequired,
    notifications: PropTypes.array
  };

  static defaultProps = {
    notifications: []
  };

  render() {
    if (!this.props.initComplete) {
      return <LoadingScreen />;
    }

    return (
      <React.Fragment>
        <Router />
        <Notifications notifications={this.props.notifications} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ notifications, app: { initComplete } }) => ({
  initComplete,
  notifications
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...authActions
    },
    dispatch
  );

const ConnectedRoot = connect(mapStateToProps, mapDispatchToProps)(Root);

export default function App() {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <ConnectedRoot />
      </MuiThemeProvider>
    </Provider>
  );
}
