export const brand = '#3cb6b6';
export const lightGrey = '#b7b7b7';
export const almostWhite = '#eee';
export const pureWhite = '#fff';

export const tangerine = '#ff9200';
export const pinkPurple = '#f41ef2';
export const white = '#e5e5e5';
export const black = '#000';
export const orangeRed = '#fd2828';

export const black3 = 'rgba(0,0,0,0.3)';

export const greyIcon = '#727272';

export const grey90 = '#e5e5e5';
export const darkGrey = '#555';
