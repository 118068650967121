import { createSelector } from 'reselect';
import getShouldSwitchToNext from './shouldSwitchToNext';

const getCurrentTrack = (state) => state.activeTrack;

const getQueue = (state) => state.queue.allIds;

const getSubsequentTracks = createSelector(
  [getCurrentTrack, getQueue, getShouldSwitchToNext],
  (currentTrack, queue, shouldSwitchToNext) => {
    if (!currentTrack) return [];
    const index = queue.indexOf(currentTrack);
    const next = queue.slice(shouldSwitchToNext ? index + 2 : index + 1);
    return next;
  }
);

export default getSubsequentTracks;
