import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as notifications } from 'react-notification-system-redux';

import activeTrack from './activeTrack';
import queue from './queue';
import isPlaying from './isPlaying';
import volume from './volume';
import currentUser from './currentUser';
import switchingVoice from './switchingVoice';
import redirectTo from './redirectTo';
import schedule from './schedule';
import playbackData from './playbackData';
import settings from './settings';
import crossFader from './crossFader';
import app from './app';

const rootReducer = combineReducers({
  activeTrack,
  app,
  queue,
  isPlaying,
  volume,
  currentUser,
  switchingVoice,
  redirectTo,
  schedule,
  playbackData,
  notifications,
  settings,
  crossFader,

  form: formReducer
});

export default rootReducer;
