import { createMuiTheme } from '@material-ui/core/styles';
import { pinkPurple, orangeRed, tangerine } from '../components/ui/colors';

export default createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: pinkPurple
    },
    secondary: {
      main: tangerine
    },
    error: {
      main: orangeRed
    }
  }
});
