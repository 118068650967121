import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import LoginPage from '../components/LoginPage';
import { login } from '../actions/auth';

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onSubmit: login
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
