/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import glamorous, { I, Div } from 'glamorous';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import { Field, reduxForm } from 'redux-form';
import { Redirect } from 'react-router-dom';

import { container } from './ui/styles';
import { orangeRed } from './ui/colors';
import BackButton from './ui/BackButton';
import ActionButton from './ui/ActionButton';
import { MIN_PASSWORD_LENGTH } from '../config';

const Container = glamorous.article({
  ...container
});

const Form = glamorous.form({
  padding: '24px'
});

const Header = glamorous.h1({
  padding: '24px'
});

const LoginError = glamorous.p({
  color: 'white',
  backgroundColor: orangeRed,
  borderRadius: '12px',
  margin: '20px auto',
  padding: '5px 30px',
  fontWeight: 600,
  textAlign: 'center',
  fontSize: '14px'
});

const FormRow = glamorous.div(
  {
    margin: '0 auto 15px'
  },
  ({ smaller }) => ({
    maxWidth: smaller ? '680px' : null
  })
);

const renderField = ({ input, label, type, meta: { touched, error }, ...custom }) => (
  <TextField
    error={touched && !!error}
    {...input}
    type={type}
    label={label}
    fullWidth
    {...custom}
  />
);

const validate = ({ password, password_confirmation: passwordConf }) => {
  const errors = {};

  if (!password || password.length < MIN_PASSWORD_LENGTH) {
    errors.password = 'required';
  }

  if (passwordConf !== password) {
    errors.password_confirmation = 'required';
  }

  return errors;
};

class PasswordResetPage extends Component {
  render() {
    const { invalid, submitting, error, submitSucceeded, t } = this.props;

    if (submitSucceeded) {
      return <Redirect to="/login" />;
    }

    return (
      <Container>
        <Header>
          <Div marginBottom="24px">
            <BackButton>
              <I className="fa fa-chevron-left" fontSize="24px" />
            </BackButton>
          </Div>

          {t('resetPassword.title')}
        </Header>

        <Form onSubmit={this.props.handleSubmit}>
          <FormRow>
            <Field
              required
              name="password"
              type="password"
              component={renderField}
              label={t('resetPassword.password.label')}
              helperText={t('resetPassword.passwordHelper')}
            />
          </FormRow>

          <FormRow>
            <Field
              required
              name="password_confirmation"
              type="password"
              component={renderField}
              label={t('resetPassword.passwordConfirmation.label')}
            />
          </FormRow>

          <ActionButton disabled={submitting || invalid}>{t('resetPassword.button')}</ActionButton>

          {error && (
            <LoginError>
              {error.code && error.code !== 'E_INTERNAL_ERROR'
                ? t(`resetPassword.errors.${error.code}`)
                : t('unknownError')}
            </LoginError>
          )}
        </Form>
      </Container>
    );
  }
}

export default reduxForm({
  validate,
  form: 'register'
})(withTranslation()(PasswordResetPage));
