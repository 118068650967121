import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = ({ isLoggedIn, ...props }) =>
  isLoggedIn ? <Route {...props} /> : <Redirect to="/login" />;

PrivateRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
};

export default connect(({ currentUser }) => ({
  isLoggedIn: !!currentUser
}))(PrivateRoute);
